import {InputText} from "primereact/inputtext";
import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Scanner} from "./Scanner";
import {MenuDescriptionProductComponent} from "./MenuDescriptionProductComponent";
import ItemsServices from "../../service/services_socdd/ItemsServices";
import {useHistory} from "react-router-dom";
import {redirectMenu} from "../scocc_components_util/Util";
import {Spinner} from "../../utilities/Spinner";
import {SelectEmpComponent} from "./SelectEmpComponent";

export const SearchBarcode = (props) => {
    const history = useHistory();

    /** codigo producto */
    const [code, setCode] = useState(null);

    const [product, setProduct] = useState()
    const [dimension, setDimension] = useState()
    const [info, setInfo] = useState()
    const [unit, setUnit] = useState()
    const [image, setImage] = useState()
    const [observation, setObservation] = useState()

    const [valid, setValid] = useState(true);

    /** para spinner*/
    const [arrayServices, setArrayServices] = useState([])
    const [load, setLoad] = useState(false)

    const itemService = new ItemsServices()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(320);

    const [indexTab, setIndexTab] = useState(0)

    /**codigo empresa */
    const [codeEmp, setCodeEmp] = useState(props.codEmp)

    let resizeWindow = () => {
        setValid(false)
        let box = document.querySelector('#divScanner');
        let width = box.offsetWidth;
        setWindowWidth((width * 95) / 100);

        setTimeout(() => {
            setValid(true)
        }, 1000);

        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {

        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);

        if (codeEmp != null) {
            searchCode()
        }

    }, []);
    const _onDetected = (res) => {
        console.log(res.codeResult.code)
        if (res) {
            setCode(res.codeResult.code)
        }
    }

    const searchCode = async (code) => {
        setLoad(true)
        setArrayServices([])
        let _arrService = [];
        await itemService.getProduct(code, props.codEmp).then(async res => {
            let obj = (res) ? res[0] : "data vacio";
            console.log(obj[0])
            setProduct(obj[0]);
            _arrService.push(1)

            await itemService.getDimension(obj[0].INVENTDIMID, props.codEmp).then(res => {
                let obj = (res) ? res[0] : "data vacio";
                console.log(obj[0])
                setDimension(obj[0]);
                _arrService.push(1)

            })
            await itemService.getInfo(obj[0].ITEMID, props.codEmp).then(res => {
                let obj = (res) ? res[0] : "data vacio";
                console.log(obj[0])
                setInfo(obj[0]);
                _arrService.push(1)
            })
            await itemService.getUnit(obj[0].ITEMID, props.codEmp).then(res => {
                let obj = (res) ? res[0] : "data vacio";
                console.log(obj[0])
                setUnit(obj[0]);
                _arrService.push(1)
            })
            await itemService.getObservation(obj[0].ITEMBARCODE, props.codEmp).then(res => {
                let obj = (res) ? res[0] : "data vacio";
                console.log(obj[0])
                setObservation(obj[0]);
                _arrService.push(1)
            })
            itemService.getImages(obj[0].ITEMBARCODE).then(res => {
                if (res != null) {
                    setImage(res.images)
                    _arrService.push(1)
                    setArrayServices(_arrService)
                }
            })

        })

    }


    return (
        <>
            <div className="card p-grid">
                <div className="p-col-12 ">
                    <Button style={{width: "100%"}} label="volver" icon="pi pi-undo" className="p-button-danger" onClick={() => redirectMenu(history)}/>
                </div>

                <div id="divScanner" className="p-col-12 p-xl-12 p-md-12 p-lg-12 p-sm-12">{valid && <Scanner onDetected={_onDetected} width={windowWidth}/>}</div>

                <div className="p-col-12 p-xl-12 p-lg-8"><InputText style={{width: "100%"}} value={code} onChange={(e) => setCode(e.target.value)}/></div>
                <div className="p-col-12 p-xl-12 p-lg-4"><Button style={{width: "100%"}} label="Buscar" icon="pi pi-search" className="p-button-rounded p-button-success" onClick={() => searchCode(code)}/></div>
            </div>
            {arrayServices.length === 6 && product != null && dimension != null && info != null && unit != null && code != null
                && <MenuDescriptionProductComponent product={product} setProducto={setProduct}
                                                    indexTab={indexTab} setIndexTab={setIndexTab}
                                                    dimension={dimension} info={info}
                                                    observation={observation}
                                                    itemBarcode={searchCode}
                                                    images={image} setImages={setImage}
                                                    unit={unit} width={windowWidth}/>}
            {arrayServices.length!== 6 && load && <Spinner/>}

        </>
    );

}
