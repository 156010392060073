import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {redirectSearchBarcode} from "../../components/scocc_components_util/Util";
import {useHistory} from "react-router-dom";
import ItemsServices from "../../service/services_socdd/ItemsServices";
import {MenuDescriptionProductComponent} from "../../components/socdd_components/MenuDescriptionProductComponent";
import {Spinner} from "../../utilities/Spinner";
import {SelectEmpComponent} from "../../components/socdd_components/SelectEmpComponent";
import {NotFound} from "../../components/socdd_components/NotFound";


export const SearchProduct = (props) => {
    const [searchCode, setSearchCode] = useState('');
    const [lastSearchedCode, setLastSearchedCode] = useState();

    const history = useHistory();

    const itemService = new ItemsServices()
    const [codeEmp,setCodeEmp]=useState(null)
    const [viewEmp,setViewEmp]=useState(true)
    const [product, setProduct] = useState()
    const [dimension, setDimension] = useState()
    const [info, setInfo] = useState()
    const [unit, setUnit] = useState()
    const [image, setImage] = useState()
    const [valid, setValid] = useState(true);

    const [observation, setObservation] = useState()
    const [arrayServices, setArrayServices] = useState([])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(320);

    const [indexTab, setIndexTab] = useState(0)
    const [load, setLoad] = useState(false)

    const barcodeInputRef = React.useRef(null);



    let resizeWindow = () => {
        setValid(false)
        let box = document.querySelector('#cardmenu');
        let width = box.offsetWidth;
        setWindowWidth((width * 95) / 100);

        setTimeout(() => {
            setValid(true)
        }, 1000);

        setWindowHeight(window.innerHeight);
    };


    useEffect(() => {

        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        if(barcodeInputRef && barcodeInputRef.current){
            barcodeInputRef.current.focus();
        }
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    const handleKeyDown = event => {


        if (event.key === 'Enter') {
            // 👇️ your logic here
            console.log('Enter key pressed ✅');
            searchProduct();
        }
    };

    const searchProduct = async () => {
        setLoad(true)
        setArrayServices([])
        let _arrService = [];

        setLastSearchedCode(searchCode);


        await itemService.getProduct(searchCode, props.codEmp).then(async res => {
            setSearchCode ('');
            let obj = (res[0].length !== 0) ? res[0] : "data vacio";
            console.log(obj);
            setProduct(obj[0]);
            if(res[0].length !== 0){
                _arrService.push(1);
            }

            await itemService.getDimension(obj[0].INVENTDIMID, props.codEmp).then(res => {
                console.log(res.length)
                console.log('paso por aca')
                let obj = (res.length !== 0) ? res[0] : "data vacio";
                setDimension(obj[0]);
                if(res.length !== 0){
                    _arrService.push(1);
                }

            })
            await itemService.getInfo(obj[0].ITEMID, props.codEmp).then(res => {
                let obj = (res.length !== 0) ? res[0] : "data vacio";
                setInfo(obj[0]);
                if(res.length !== 0){
                    _arrService.push(1);
                }
            })
            await itemService.getUnit(obj[0].ITEMID, props.codEmp).then(res => {
                let obj = (res.length !== 0) ? res[0] : "data vacio";
                setUnit(obj[0]);
                if(res.length !== 0){
                    _arrService.push(1);
                }
            })
            await itemService.getObservation(obj[0].ITEMBARCODE, props.codEmp).then(res => {
                let obj = (res.length !== 0) ? res[0] : "data vacio";
                setObservation(obj[0]);
                if(res.length !== 0){
                    _arrService.push(1);
                }
            })
            await itemService.getImages(obj[0].ITEMBARCODE).then(res => {
                if (res != null) {
                    setImage(res.images)
                    _arrService.push(1)
                }
            })
            setArrayServices(_arrService)

        });


    }

    console.log(arrayServices.length)
    return (
        <>
            {!props.codEmp && <div id="cardmenu" className="card p-grid">
                <SelectEmpComponent codEmp={props.codEmp} setCodEmp={props.setCodEmp} viewEmp={props.viewEmp} setViewEmp={props.setViewEmp}/>
            </div>}

            {props.codEmp != null &&  <div id="cardmenu" className="">
                <div className="p-grid">
                    <div className="p-col-2 ">
                        <Button  label="" icon="pi pi-camera" className="p-button-rounded p-button-primary" onClick={() => {
                            redirectSearchBarcode(history)
                            props.setCodEmp(props.codEmp);
                        }}/>
                    </div>
                    <div className="p-col-8  ">
                        <span className="p-float-label p-input-icon-left" style={{width: "100%"}}>
                            <i className="pi pi-search"/>
                            <InputText style={{width: "100%"}}
                                       ref={barcodeInputRef}
                                       id="barcode-field"
                                       value={searchCode}
                                       onChange={(event) => setSearchCode(event.target.value)}
                                       onKeyDown={handleKeyDown}
                            />
                            <label htmlFor="username">Código de barra</label>
                        </span>
                    </div>
                    <div className="p-col-2 ">
                        <Button  label=""
                                 icon="pi pi-search"
                                 className="p-button-rounded p-button-success"
                                 onClick={() => searchProduct()}
                                 disabled={!searchCode}
                        />
                    </div>
                </div>
                <div style={{'textAlign': 'center'}}>
                    <span >{lastSearchedCode}</span>
                </div>
            </div>}
            {arrayServices.length === 6 && product != null && dimension != null && info != null && unit != null && searchCode != null
                && <MenuDescriptionProductComponent product={product} setProducto={setProduct}
                                                    indexTab={indexTab} setIndexTab={setIndexTab}
                                                    dimension={dimension} info={info}
                                                    observation={observation}
                                                    itemBarcode={searchCode}
                                                    images={image} setImages={setImage}
                                                    unit={unit} width={windowWidth}/>}
            {arrayServices.length === 1 && <NotFound/>}
            {arrayServices.length === 0 && load && <Spinner/>}

        </>


    );

}
