import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import AppSubmenu from './AppSubmenu';
import {SelectEmpComponent} from "./components/socdd_components/SelectEmpComponent";

const AppMenu = (props) => {



    return (
        <div className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/" className="logo">
                <span className="app-name">SOCDD</span>
            </Link>

            <div className="layout-menu-container">
                <AppSubmenu items={props.model} menuMode={props.menuMode} parentMenuItemActive menuActive={props.active} mobileMenuActive={props.mobileMenuActive} root onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick} />

                <SelectEmpComponent  codEmp={props.codEmp} setCodEmp={props.setCodEmp}
                                     viewEmp={props.viewEmp} setViewEmp={props.setViewEmp}/>
            </div>
        </div>
    );
}

export default AppMenu;
