export const Loading = () => {
    return (
        <div className="loader-container">
            <div className="loader">
                <div className="bottom-section">
                    <div className="bottom"></div>
                    <div className="bottom"></div>
                    <div className="bottom"></div>
                    <div className="bottom"></div>
                </div>
                <div className="top-section">
                    <div className="bottom"></div>
                    <div className="bottom"></div>
                    <div className="bottom"></div>
                    <div className="bottom"></div>
                </div>
                <div className="middle"></div>
            </div>
        </div>
    )
}
