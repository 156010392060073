import axios from "axios"
import {properties} from "../../properties";

export default class ItemsServices {

    getProduct(code,codeEmp) {
        console.log(code)
        return axios.get(properties.url_api + '/codigo-barra',
            {
                params: {
                    barcode: code,
                    codeEmp: codeEmp
                }
            }).then(res => res.data).catch(err => []);
    }

    getDimension(inventDim,codeEmp) {
        console.log(inventDim)
        return axios.get(properties.url_api + '/dimesion',
            {
                params: {
                    inventDimId: inventDim,
                    codeEmp: codeEmp
                }
            }
        ).then(res => res.data).catch(err => [])
    }

    getInfo(itemId,codeEmp) {
        return axios.get(properties.url_api + '/info', {
            params: {
                itemId: itemId,
                codeEmp:codeEmp
            }
        }).then(res => res.data).catch(err => []);
    }

    getUnit(itemId,codeEmp) {
        return axios.get(properties.url_api + '/unidad',
            {
                params: {
                    itemId: itemId,
                    codeEmp:codeEmp
                }
            }).then(res => res.data).catch(err => [])
    }
    getObservation(itemBarcode,codEmp){
        return axios.get(properties.url_api+'/observacion',
            {
                params:{
                    itemBarcode:itemBarcode,
                    codeEmp:codEmp
                }
            }).then(res=>res.data).catch(err=>[])
    }
    getImages(itemBarcode){
        return axios.get(properties.url_api_base+'/images',
            {
                params:{
                    barcode:itemBarcode
                }

        }).then(res=>res.data).catch(err=>[])
    }
    postImages(newImage){
        return axios.post(properties.url_api_base+'/images',newImage).then(res=>res).catch(err=>[])
    }
    deleteImage(idImg){
        return axios.delete(properties.url_api_base+'/images/'+idImg).then(res=>res).catch(err=>[])
    }
    deleteMultiplesImages(arrayImg){
        let arrAxios=[]
        for (let idImg of arrayImg) {
            arrAxios.push(axios.delete(properties.url_api_base+'/images/'+idImg))
        }return axios.all(arrAxios).then(res=>res).catch(err=>[])
    }


}
