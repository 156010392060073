import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FileUpload} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {Galleria} from "primereact/galleria";
import {Checkbox} from 'primereact/checkbox';
import ItemsServices from "../../service/services_socdd/ItemsServices";

import {CameraComponent} from "./CameraComponent";
import {ProgressSpinner} from "primereact/progressspinner";
import {ProgressBar} from "primereact/progressbar";
import {useKeycloak} from "@react-keycloak/web";
import {Image} from "primereact/image";
import {Toolbar} from "primereact/toolbar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export const ImageComponent = (props) => {
    const toast = useRef(null)
    const [descriptionImage, setDescriptionImage] = useState();
    const [openDialogImage, setOpenDialogImage] = useState(false);

    const [images, setImages] = useState()
    const [imageLoad, setImageLoad] = useState(false)
    const [b64DataImg, setB64DataImg] = useState([])
    const [principalImg, setPrincipalImg] = useState(false);
    const itemService = new ItemsServices();
    /** eliminar imagen */
    const [imgSelect, setImgSelect] = useState();
    const [selectMultipleImg, setSelectMultipleImg] = useState(null);
    const [dialogDeleteImg, setDialogDeleteImg] = useState(false)

    /**variables para cam */
    const [openCam, setOpenCam] = useState(false)
    const [imgCam, setImgCam] = useState(null);

    /**opciones cam o select  image*/
    const [selectCam, setSelectCam] = useState(false)
    const [selectImg, setSelectImg] = useState(false)

    /**barra progreso*/
    const [processLoad, setProcessLoad] = useState(0)

    /** opcion responsivo Galleria*/
    let
        responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '960px',
                numVisible: 4
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];

    /** opcion del boton para cargar imagen*/
    const chooseOptions = {label: 'Seleccionar', icon: 'pi pi-fw pi-check', className: 'p-button-primary', style: {width: '100%'}};

    /** keycloak */
    const {keycloak} = useKeycloak()

    /**tabla seleccion de imagenes*/
    const dt = useRef(null)
    const [openDialogDeleteImages, setOpenDialogDeleteImages] = useState(false)
    const [dialogConfirmDeleteMultiplesImg,setDialogConfirmDeleteMultiplesImg]=useState(false)

    useEffect(() => {
        //loadImage()
    }, [])
    const loadImage = () => {
        itemService.getImages(props.itemBarcode).then(res => {
            if (res != null) {
                setImages(res.images)
                props.setImages(res.images)
            }
        })

    }
    const itemTemplate = (item) => {
        setImgSelect(item);
        return <Image src={item.original} alt={item.alt} width="100%" preview/>
    }
    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnail} alt={item.alt} style={{display: 'block'}}/>
    }
    const upload = () => {
        console.log(("uploadImage"))
        toast.current.show({severity: 'info', summary: 'Imagen', detail: 'descripcion invalida'});
    }
    const uploadImagen = async (event) => {
        await uploadHandler(event, "image")
        setImageLoad(true)
    }
    const uploadHandler = async (event, chargeType) => {
        let newData = []
        let b64 = await getBase64(event.files[0])
        newData = b64.split(",", b64.length);
        setB64DataImg(newData)
        if (newData.length === 2) {
            setProcessLoad(100)
        } else {
            setProcessLoad(0)
        }

    }
    const getBase64 = async (file) => {
        let b64 = await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(file)
        })
        return b64;
    }
    const saveImage = () => {
        let valid = true
        if (!descriptionImage || descriptionImage.length < 2 || descriptionImage.length > 140) {
            valid = false
            toast.current.show({severity: 'error', summary: 'Imagen', detail: 'descripcion invalida'})
        }
        if (b64DataImg.length < 2 || b64DataImg == null) {
            valid = false
            setImageLoad(false)
            toast.current.show({severity: 'error', summary: 'Imagen', detail: 'imagen no cargada'})
        }
        if (valid) {
            let objImage = {
                type: b64DataImg[0],
                data: b64DataImg[1],
                itemBarCode: props.itemBarcode,
                description: descriptionImage,
                principal: principalImg
            }
            itemService.postImages(objImage).then(res => {
                if (res.status === 200) {
                    loadImage()
                    setOpenDialogImage(false)
                    setDescriptionImage(null)
                    setPrincipalImg(false)
                    toast.current.show({severity: 'success', summary: 'Imagen', detail: 'Imagen Guardada', life: 3000})
                }
            })

        }
    }
    const saveImagCam = () => {
        let arrayImgCam = imgCam.split(",", imgCam.length)
        let valid = true
        if (!descriptionImage || descriptionImage.length < 2 || descriptionImage.length > 140) {
            valid = false
            toast.current.show({severity: 'error', summary: 'Imagen', detail: 'descripcion invalida'})
        }
        if (arrayImgCam.length < 2 || arrayImgCam == null) {
            valid = false
            toast.current.show({severity: 'error', summary: 'Imagen', detail: 'imagen no cargada'})
        }
        if (valid) {
            let objImage = {
                type: arrayImgCam[0],
                data: arrayImgCam[1],
                itemBarCode: props.itemBarcode,
                description: descriptionImage,
                principal: principalImg
            }
            itemService.postImages(objImage).then(res => {
                if (res.status === 200) {
                    loadImage()
                    setOpenDialogImage(false)
                    setDescriptionImage(null)
                    setPrincipalImg(false)
                    setOpenCam(false)
                    setImageLoad(false)
                    setImgCam(null)
                    toast.current.show({severity: 'success', summary: 'Imagen', detail: 'Imagen Guardada', life: 3000})
                }
            })
        }
    }
    const deleteImage = () => {
        itemService.deleteImage(imgSelect.id).then(res => {
            if (res.status === 200) {
                loadImage()
                setDialogDeleteImg(false)
                toast.current.show({severity: 'success', summary: 'Imagen', detail: 'Imagen Eliminada Correctamente'})
            } else {
                toast.current.show({severity: 'error', summary: 'Imagen', detail: 'Imagen no Eliminada'})
            }
        })
    }
    const deleteMultipleImage = () => {
        let arrIdImages = []
        let successDelete = 0
        let errorDelete = 0
        for (let imgSelect of selectMultipleImg) {
            arrIdImages.push(imgSelect.id)
        }
        itemService.deleteMultiplesImages(arrIdImages).then(res => {
            for (let data of res) {
                if (data.status === 200) {
                    successDelete = successDelete + 1
                } else {
                    errorDelete=errorDelete+1
                }
            }
            if (successDelete === arrIdImages.length){
                loadImage()
                setOpenDialogDeleteImages(false)
                setSelectMultipleImg(null)
                setDialogConfirmDeleteMultiplesImg(false)
                toast.current.show({severity: 'success', summary: 'Imagen', detail: 'Imagenes Eliminadas Correctamente'})
            }else{
                loadImage()
                setSelectMultipleImg(null)
                setDialogConfirmDeleteMultiplesImg(false)
                toast.current.show({severity: 'error', summary: 'Imagen', detail: 'Error al Eliminar las imagenes'})
            }

        })
    }
    const cancel = () => {
        setOpenDialogImage(false)
        setImageLoad(false)
        setDescriptionImage(null)
        setSelectImg(false);
        setSelectCam(false);
        setProcessLoad(0)
        toast.current.show({severity: 'warn', summary: 'Imagen', detail: 'Operacion Cancelada'})
    }
    const cancelDeleteImg = () => {
        setDialogDeleteImg(false)
        setOpenDialogDeleteImages(false)
        setImgSelect(null)
        setSelectMultipleImg(null)
        toast.current.show({severity: 'warn', summary: 'Imagen', detail: 'Operación Cancelada'})
    }
    const cancelDeleteMultipleImg = () => {
        setDialogConfirmDeleteMultiplesImg(false)
        setImgSelect(null)
        setSelectMultipleImg(null)
        toast.current.show({severity: 'warn', summary: 'Imagen', detail: 'Operación Cancelada'})
    }


    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <Image src={rowData.original} alt={rowData.alt} width="125" preview/>
            </>
        )
    }
    const footerDeleteImg = (
        <>
            <div className="p-grid">
                <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                    <Button label="No" icon="pi pi-times" className="p-button-danger" onClick={() => cancelDeleteImg()}/>
                </div>
                <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                    <Button label="Si" icon="pi pi-check" className="p-button-success" onClick={() => deleteImage()}/>
                </div>
            </div>


        </>
    );
    const footerDeleteMultipleImages = (
        <>
            <div className="p-grid">
                <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                    <Button style={{width:'100%'}} label="Cancelar" icon="pi pi-times" className="p-button-danger" onClick={() => cancelDeleteImg()}/>
                </div>
                <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                    <Button style={{width:'100%'}} label="Eliminar" icon="pi pi-check" className="p-button-primary" onClick={() => setDialogConfirmDeleteMultiplesImg(true)} disabled={!selectMultipleImg}/>
                </div>
            </div>

        </>
    );
    const footerConfirmDeleteImg = (
        <>
            <div className="p-grid">
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                    <Button  label="No" icon="pi pi-times" className="p-button-danger" onClick={() =>cancelDeleteMultipleImg() }/>
                </div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                    <Button label="Si" icon="pi pi-check" className="p-button-primary" onClick={() => deleteMultipleImage()}/>
                </div>
            </div>


        </>
    );



    return (
        <>
            <div>
                <Toast ref={toast}/>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12 p-md-12 p-lg-12 p-sm-12">
                        <div>
                            <div className="card">
                                {props.images && <Galleria value={props.images} responsiveOptions={responsiveOptions} numVisible={7} circular style={{maxWidth: '800px'}}
                                                           item={itemTemplate} thumbnail={thumbnailTemplate}
                                />}
                                {!props.images && <h4>No hay imagenes asociadas</h4>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-xl-4 p-md-4 p-lg-4 p-sm-12"><b>Envase: </b>{(props.product.container) ? props.product.container : " -"}</div>
                    <div className="p-col-12 p-xl-4 p-md-4 p-lg-4 p-sm-12"><b>Ubicacion: </b>{(props.product.location) ? props.product.location : " -"}</div>
                    <div className="p-col-12 p-xl-4 p-md-4 p-lg-4 p-sm-12"><b>Pack: </b>{(props.product.pack) ? props.product.pack : " -"}</div>
                    {keycloak.hasRealmRole("socdd-supervisor") && <>
                        <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                            <Button style={{width: "100%"}} label="Agregar Imagen" icon="pi pi-plus" onClick={() => {
                                setOpenDialogImage(true)
                            }}/>
                        </div>

                        <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                            <Button style={{width: "100%"}} label="Eliminar Imágenes" icon="pi pi-trash"
                                    className="p-button-danger"
                                    onClick={() => {
                                        console.log("Se abre dialogo con selector de imagenes")
                                        setOpenDialogDeleteImages(true)
                                    }}/>
                        </div>

                    </>}
                </div>
            </div>
            <Dialog visible={openDialogImage} onHide={cancel} header={"Agregar Nueva Imagen"}
                    breakpoints={{'960px': '75vw'}} style={{width: '50vw'}}>
                <div className="p-col-12">
                    <Toast ref={toast}/>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12 p-md-12 p-lg-12 p-sm-12" style={{width: "100%"}}>
                            <span className="p-float-label">
                                <InputText id="description" style={{width: "100%"}} value={descriptionImage} onChange={(e) => setDescriptionImage(e.target.value)}
                                           className={(!descriptionImage) ? "p-invalid p-d-block" : ""}/>
                                <label htmlFor="description">Descripción</label>
                            </span>
                            {!descriptionImage && <small id="description2" className="p-error p-d-block">*Ingrese descripción.</small>}
                        </div>
                        <div className="p-col-12 p-xl-4 p-md-4 p-lg-4 p-sm-12">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="binary" checked={principalImg} onChange={e => setPrincipalImg(e.checked)}/>
                                <label htmlFor="binary">Foto Principal</label>
                            </div>
                        </div>
                        <div className="p-col-12 p-xl-4 p-md-4 p-lg-4 p-sm-12">
                            <Button style={{width: "100%"}} icon="pi pi-camera" className="p-button-primary" onClick={() => {
                                setSelectCam(true)
                                setOpenCam(true)
                                setSelectImg(false)
                            }}/>
                        </div>

                        <div className="p-col-12 p-xl-4 p-md-4 p-lg-4 p-sm-12">
                            <FileUpload chooseOptions={chooseOptions}
                                        mode='basic'
                                        name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php"
                                        accept="image/*" maxFileSize={1000000}
                                        auto={true}
                                        customUpload uploadHandler={uploadImagen} onUpload={upload}/>
                        </div>

                        {selectCam && <div className="p-col-12 p-xl-12 p-md-21 p-lg-12 p-sm-12">
                            {openCam === true && <CameraComponent openCam={openCam} setOpenCam={setOpenCam} imgCam={imgCam} setImgCam={setImgCam} processLoad={processLoad} setProcessLoad={setProcessLoad}/>}
                        </div>}

                        <div className="p-col-12 p-xl-12 p-md-21 p-lg-12 p-sm-12">
                            <h6>Imagen Cargada</h6>
                            <ProgressBar value={processLoad} color="success"/>
                        </div>

                        {openCam === false &&
                            <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                                <Button style={{width: "100%"}} label="Cancelar" icon="pi pi-times" className="p-button-danger" onClick={() => {
                                    cancel()
                                }}/>
                            </div>}
                        {openCam === false && imgCam == null &&
                            <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                                <Button style={{width: "100%"}} label="Guardar" icon="pi pi-check" className="p-button-success" onClick={() => saveImage()} disabled={!descriptionImage || imageLoad === false}/>
                            </div>
                        }
                        {imgCam != null && openCam === false &&
                            <div className="p-col-12 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                                <Button style={{width: "100%"}} label="Guardar" icon="pi pi-check" className="p-button-primary" onClick={() => saveImagCam()} disabled={!descriptionImage || imgCam == null}/>
                            </div>
                        }
                    </div>
                </div>
            </Dialog>

            <Dialog visible={dialogDeleteImg}
                    style={{width: '450px'}} header="Eliminar Imagen" modal
                    footer={footerDeleteImg}
                    onHide={() => cancelDeleteImg()}>
                <div className="p-d-flex p-ai-center p-jc-center p-grid p-col-12">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                    {imgSelect && <span>¿Desea eliminar la imagen?</span>}
                    <br/>
                </div>
                <div className="p-grid p-col-12">
                    {imgSelect && <Image src={imgSelect.original} alt={imgSelect.alt} width="250" preview/>}
                </div>
            </Dialog>

            <Dialog visible={openDialogDeleteImages}
                    header={"Eliminar Imágenes"}
                    modal className="p-fluid"
                    footer={footerDeleteMultipleImages}
                    onHide={() => cancelDeleteImg()}
                    breakpoints={{'960px': '75vw'}} style={{width: '40vw'}}>

                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <DataTable ref={dt} value={props.images} selection={selectMultipleImg} onSelectionChange={(e) => setSelectMultipleImg(e.value)}
                                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                   emptyMessage="Imágenes no cargadas." header={"Imágenes del Producto"}>
                            <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                            <Column header="Imagen" className="p-text-center" body={imageBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={dialogConfirmDeleteMultiplesImg}
                    breakpoints={{'960px': '75vw'}} style={{width: '40vw'}}
                    style={{width: '450px'}} header="Eliminar Imagenes" modal
                    footer={footerConfirmDeleteImg}
                    onHide={() => cancelDeleteMultipleImg()}>
                <div className="p-d-flex p-ai-center p-jc-center p-grid p-col-12">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                    {selectMultipleImg && <span>Desea eliminar <b>{(selectMultipleImg)?selectMultipleImg.length:""}</b> imagenes ?</span>}
                    <br/>
                </div>
            </Dialog>

        </>
    )

}
