import {TabMenu} from "primereact/tabmenu";
import {useEffect, useRef, useState} from "react";
import {ProductComponent} from "./ProductComponent";
import {TabPanel, TabView} from "primereact/tabview";
import {DimensionComponent} from "./DimensionComponent";
import {Storage} from "./Storage";
import {ImageComponent} from "./ImageComponent";
import {ObservationComponent} from "./ObservationComponent";


export const MenuDescriptionProductComponent  = (props) => {


    return (
        <div className="card">
            <h5>{(props.info)?props.info.ITEMNAME:""}</h5>
            {/**<TabView activeIndex={props.indexTab} onTabChange={(e) => props.setIndexTab(e.index)} className="tabview-custom">*/}
            <TabView activeIndex={props.indexTab} onTabChange={(e)=>props.setIndexTab(e.index)} className="tabview-custom">
                <TabPanel header={(props.width<640)?'':"Identificación del Artículo"} leftIcon="pi pi-fw pi-id-card">
                   <ProductComponent product={props.product} name={props.info.ITEMNAME}
                                     configID={props.dimension.CONFIGID}
                                     acabado={props.dimension.SIZEID}
                                     mark={props.dimension.COLORID}
                                     observation={props.observation}
                   />
                </TabPanel>
                {/**<TabPanel header={(props.width < 640) ? '' : "Observaciones"} leftIcon="pi pi-fw pi-comments">
                    <ObservationComponent product={props.product} setProduct={props.setProduct} name={props.info.ITEMNAME}/>
                </TabPanel>*/}
                <TabPanel header={(props.width<640)?'':"Dimensiones"} leftIcon="pi pi-fw pi-box">
                    <DimensionComponent info={props.info} name={props.info.ITEMNAME}  />
                </TabPanel>
                <TabPanel  header={(props.width<640)?'':"Almacenamiento"} leftIcon="pi pi-fw pi-map-marker">
                    <Storage product={props.product}  name={props.info.ITEMNAME}  dimension={props.dimension} />
                </TabPanel>
                <TabPanel header={(props.width<640)?'':"Imágenes"} leftIcon="pi pi-fw pi-image">
                    <ImageComponent product={props.product} name={props.info.ITEMNAME} itemBarcode={props.itemBarcode}
                                    images={props.images} setImages={props.setImages}/>
                </TabPanel>

            </TabView>
        </div>
    )
}
