import React, {useState, useEffect, useLayoutEffect} from 'react'
import Quagga from '@ericblade/quagga2'


export const Scanner = (props) => {

    const _onDetected = (result) => {
        props.onDetected(result)
    }



    useEffect(() => {
        Quagga.init(
            {
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        width: props.width,
                        height: 320,
                        facingMode: 'environment',
                    },
                },
                locator: {
                    halfSample: true,
                    patchSize: "large", // x-small, small, medium, large, x-large
                    debug: {
                        showCanvas: true,
                        showPatches: false,
                        showFoundPatches: false,
                        showSkeleton: false,
                        showLabels: false,
                        showPatchLabels: false,
                        showRemainingPatchLabels: false,
                        boxFromPatches: {
                            showTransformed: true,
                            showTransformedBox: true,
                            showBB: true
                        }
                    }
                },
                numOfWorkers: 4,
                decoder: {
                    readers: [
                        "code_128_reader",
                        "code_39_reader",
                        "code_39_vin_reader"
                    ],
                    debug: {
                        drawBoundingBox: true,
                        showFrequency: true,
                        drawScanline: true,
                        showPattern: true
                    },
                },
                locate: true,
            },
            function (err) {
                if (err) {
                    return console.log(err)
                }
                Quagga.start()
            },
        )
        Quagga.onDetected(_onDetected)

    }, []);

    return (
        <div id="interactive" className="viewport"/>
    )


}
