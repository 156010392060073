import { ProgressSpinner } from 'primereact/progressspinner';

export const Spinner = () => {
    return (
        <div className="loader-container" style={{background:"white",height:"50%"}}>
            <div className="loader">
                <div className="bottom-section">

                </div>
                <div className="top-section">

                </div>
                <ProgressSpinner />
            </div>
        </div>
    )
}
