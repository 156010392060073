import {Camera} from "react-camera-pro";
import {Button} from "primereact/button";
import React, {useRef, useState} from "react";
import {Dialog} from "primereact/dialog";


export const CameraComponent = (props) => {

    const camera = useRef();
    const [img, setImg] = useState(null)

    const saveImage = () => {
        console.log(img)
        props.setImgCam(img)
        if (img!=null){
            props.setProcessLoad(100)
        }else{
            props.setProcessLoad(0)
        }
        props.setOpenCam(false)
    }
    const cancelImg = () => {
        setImg(null)
        props.setOpenCam(false)
    }
    const replay = () => {
        setImg(null)
    }

    return (
        <div>
            <Dialog visible={props.openCam}  breakpoints={{'960px': '75vw'}} style={{width: '50vw'}}
                    header={"Tomar Imagen"}
                    onHide={() => props.setOpenCam(false)}>

                {img == null && <Camera ref={camera} facingMode='environment'/>}

                {img != null &&
                    <div>
                        <img src={img} alt='imagen previa' width={"100%"} height={"100%"}/>
                    </div>
                }

                {img == null &&
                    <div className="p-flex p-flex-row-reverse p-flex-wrap">
                        <div className="p-flex p-align-items-center p-justify-content-center w-4rem h-4rem">
                            {img == null &&
                                <Button icon="pi pi-camera" onClick={() => {
                                    setImg(camera.current.takePhoto())
                                }}/>}
                        </div>
                        <br/>
                        <div className="p-flex p-align-items-center p-justify-content-center w-4rem h-4rem">
                            {img == null &&
                                <Button icon="pi pi-times" className="p-button-danger" onClick={() => cancelImg()}/>
                            }
                        </div>
                    </div>}

                <div className="p-grid">
                    <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                        {img != null &&
                            <Button style={{width: "100%"}} label="Nueva" icon="pi pi-refresh" className="p-button-primary" onClick={() => replay()}/>
                        }
                    </div>
                    <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12">
                        {img != null &&
                            <Button style={{width: "100%"}} label="Siguiente" icon="pi pi-check" className="p-button-success" onClick={() => saveImage()}/>
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
