

export  const redirectSearchBarcode = (history) => {

    if (history.location.pathname === '/SearchBarcode') {
        history.push({pathname: 'SearchBarcode'});
        history.go(0);
    } else {
        history.push({pathname: 'SearchBarcode'});
    }
}
export  const redirectMenu = (history) => {

    if (history.location.pathname === '/SearchProduct') {
        history.push({pathname: '/'});
        history.go(0);
    } else {
        history.push({pathname: '/'});
    }
}

