import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import {Loading} from "./utilities/Loading";
const axios = require('axios');
//import reportWebVitals from './reportWebVitals';

const keycloak = new Keycloak('keycloak.json');

const keycloakProviderInitConfig = {
    onLoad: 'login-required',
    enableLogging: true

}
const loadingComponent = <Loading/>;

const onKeycloakEvent = (event, error) => {
    if(event==='onAuthSuccess') {
        axios.defaults.headers.common = {'Authorization': `Bearer ${keycloak.token}`};
    }
};

const onKeycloakTokens = tokens => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${tokens.token}`}
};

const app = (
    <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakProviderInitConfig}
        LoadingComponent={loadingComponent}
        onEvent={onKeycloakEvent}
        onTokens={onKeycloakTokens}>
        <HashRouter >
            <AppWrapper hashType={"noslash"} ></AppWrapper>
        </HashRouter>
    </ReactKeycloakProvider>
);

ReactDOM.render(app, document.getElementById("root"));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
