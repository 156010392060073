import {Card} from "primereact/card";
import {useState} from "react";


export const ProductComponent = (props) => {

    return (
        <div>
            <div className="p-grid">
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Código: "}</b>{(props.product.ITEMID)?props.product.ITEMID:" -"}</p></div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Configuración: "}</b>{(props.configID) ? props.configID : " -"}</p></div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Acabado: "}</b>{(props.acabado) ? props.acabado :" -"}</p></div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Marca: "}</b>{(props.mark) ? props.mark : " -"}</p></div>
                <div className="p-col-12 p-xl-12 p-md-12 p-lg-12 p-sm-12"><p style={{whiteSpace: 'pre-line'}}><i className="pi pi-info-circle"/><b>{" Observación: "}</b>{(props.observation) ? props.observation.COMMENTS : " -"}</p></div>
            </div>
        </div>
    )
}

