import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <span className="app-name">Ducasse SOCDD</span>
            </div>
            <span className="copyright">&#169; Blueprints IT</span>
        </div>
    );
}

export default AppFooter;
