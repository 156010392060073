export const Storage = (props) => {

    return (
        <div>
            <div className="p-grid">
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Código Bodega: "}</b>{(props.dimension.INVENTLAOCATIONID)?props.dimension.INVENTLAOCATIONID:" -"}</p></div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Ubicación: "}</b>{(props.dimension.WMSLOCATIONID)?props.dimension.WMSLOCATIONID:" -"}</p></div>

                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Código lote: "}</b>{(props.dimension.BATCHID)?props.dimension.BATCHID:" -"}</p></div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"N° De Serie: "}</b>{(props.dimension.SERIALID)?props.dimension.SERIALID:" -"}</p></div>
            </div>
        </div>
    )
}
