import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {useEffect, useState} from "react";
import CodigoEmpresaService from "../../service/services_socdd/CodigoEmpresaService";


export const SelectEmpComponent = (props) => {

    const [codeEmp, setCodeEmp] = useState(null)
    const [codEmpresas, setCodEmpresas] = useState()
    const codigoService = new CodigoEmpresaService()

    useEffect(() => {
        codigoService.getCodigoEmp().then(res => {
            console.log(res)
            if (res.status === 200) {
                setCodEmpresas(res.data)
            }
        })
    }, [])

    const onChangeCodEmp = (e) => {
        props.setCodEmp(e.value)
    }


    return (
        <>
            <div className="p-col-12 p-xl-12 p-md-12 p-lg-12 p-sm-12">
                <Dropdown style={{width: '100%'}} appendTo={document.body}
                          value={props.codEmp}
                          onChange={onChangeCodEmp}
                          options={codEmpresas}
                          optionLabel="nombre" optionValue="codigo" placeholder="Seleccione empresa"/>
            </div>
            <div className="p-col-12 p-xl-12 p-md-12 p-lg-12 p-sm-12">
                <Button style={{width: '100%'}} label="Seleccionar Empresa" onClick={() => {
                    setCodeEmp(null)
                    props.setCodEmp(null)
                }}/>
            </div>
        </>
    )
}
