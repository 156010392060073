export const DimensionComponent = (props) => {


    return (
        <div>
            <div className="p-grid">
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Volumen: "}</b>{(props.info.UNITVOLUME) ? props.info.UNITVOLUME +" m³"  : " -"}</p></div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Alto: "}</b>{(props.info.GROSSHEIGHT) ? props.info.GROSSHEIGHT +" cm" : " -"}</p></div>

                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Ancho: "}</b>{(props.info.GROSSWIDTH) ? props.info.GROSSWIDTH +" cm" : " -"}</p></div>
                <div className="p-col-6 p-xl-6 p-md-6 p-lg-6 p-sm-12"><p><b>{"Profundidad: "}</b>{(props.info.GROSSDEPTH) ? props.info.GROSSDEPTH +" cm": " -"}</p></div>

                <div className="p-col-12 p-xl-6 p-md-12 p-lg-12 p-sm-12"><p><b>{"Peso: "}</b>{(props.info.HEIGHT ) ? props.info.HEIGHT +" Kg" : " -"}</p></div>
            </div>
        </div>
    )
}
