import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import {useKeycloak} from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import {SelectEmpComponent} from "./components/socdd_components/SelectEmpComponent";

const AppTopbar = (props) => {
    const [codeEmp,setCodeEmp]=useState(null)
    const {keycloak} = useKeycloak();

    const notificationsItemClassName = classNames('notifications-item', {'active-menuitem': props.topbarNotificationMenuActive});
    const profileItemClassName = classNames('profile-item', {'active-menuitem fadeInDown': props.topbarUserMenuActive});


    return (
        <div className="layout-topbar">

            <div className=" topbar-left">
                <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-left"></i>
                </button>
                <span className="topbar-separator"></span>
                <div className="layout-breadcrumb viewname" style={{textTransform: 'uppercase'}}>
                    <AppBreadcrumb routers={props.routers}/>
                </div>
                <ul className="topbar-menu clase-min-especial">
                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <i className="pi pi-user"></i>
                            <span className="profile-name">{(keycloak.tokenParsed) ? keycloak.tokenParsed.name : ''}</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            <li>
                                <button type="button" className="p-link" onClick={() => keycloak.logout()}>
                                    <i className="pi pi-power-off"></i>
                                    <span>Cerrar Sesión</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className="topbar-right clase-especial">
                <ul className="topbar-menu">
                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <i className="pi pi-user"></i>
                            <span className="profile-name">{(keycloak.tokenParsed) ? keycloak.tokenParsed.name : ''}</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            <li>
                                <button type="button" className="p-link" onClick={() => keycloak.logout()}>
                                    <i className="pi pi-power-off"></i>
                                    <span>Cerrar Sesión</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>


            <AppMenu model={props.menu} menuMode={props.menuMode} active={props.menuActive} mobileMenuActive={props.staticMenuMobileActive}
                     onMenuClick={props.onMenuClick} onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick}
                     codEmp={props.codEmp} setCodEmp={props.setCodEmp}
                     viewEmp={props.viewEmp} setViewEmp={props.setViewEmp}
            ></AppMenu>

            <div className="layout-mask modal-in"></div>

        </div>
    );
}

export default AppTopbar;
