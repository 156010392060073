import {Card} from "primereact/card";
import {useState} from "react";


export const NotFound  = (props) => {


    return (
        <div className="card">
            <h5>{"Producto no encontrado."}</h5>
        </div>
    )
}
